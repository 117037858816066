import React, { Component } from "react";
import axios from "axios";
import ContentBuilder from "@innovastudio/contentbuilder";
// import ContentBuilder from './src/contentbuilder';
import "./contentbuilder.css";
import { GetData, PostData, apiClient } from "../../api";
import { get } from "lodash";
import { NumberParam, useQueryParam } from "use-query-params";

class BuilderControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: this.props.pageHtml
    };

    this.saveContent = this.saveContent.bind(this);
    this.saveContentAndFinish = this.saveContentAndFinish.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  componentDidMount() {
    // Load language file first
    this.loadLanguageFile(this.props.languageFile, () => {
      // Then init the ContentBuilder
      this.obj = new ContentBuilder({
        container: this.props.container,
        scriptPath: this.props.scriptPath,
        pluginPath: this.props.pluginPath,
        modulePath: this.props.modulePath,
        fontAssetPath: this.props.fontAssetPath,
        assetPath: this.props.assetPath,
        imageSelect: this.props.imageSelect,
        fileSelect: this.props.fileSelect,
        largerImageHandler: this.props.largerImageHandler,
        itemData: this.props.itemData,
        onLargerImageUpload: (e) => {
          console.log("onLargerImageUpload", e);
          const selectedImage = e.target.files[0];
          const filename = selectedImage.name;
          const reader = new FileReader();
          reader.onload = (e) => {
            let base64 = e.target.result;
            base64 = base64.replace(/^data:image\/(png|jpeg);base64,/, "");

            // Upload image process
            axios
              .post(this.props.largerImageHandler, {
                image: base64,
                filename: filename
              })
              .then((response) => {
                const uploadedImageUrl = response.data.url; // get saved image url
                this.obj.applyLargerImage(uploadedImageUrl); // set input
              })
              .catch((err) => {
                console.log(err);
              });
          };
          reader.readAsDataURL(selectedImage);
        }
      });

      this.obj.loadSnippets(this.props.snippetFile); // Load snippet file

      this.obj.loadHtml(this.state.html);
    });

    // https://stackoverflow.com/questions/37949981/call-child-method-from-parent
    if (this.props.doSave) this.props.doSave(this.saveContent); // Make it available to be called using doSave
    if (this.props.doSaveAndFinish)
      this.props.doSaveAndFinish(this.saveContentAndFinish);
    if (this.props.doDestroy) this.props.doDestroy(this.destroy);
  }

  loadLanguageFile = (languageFile, callback) => {
    if (!this.isScriptAlreadyIncluded(languageFile)) {
      const script = document.createElement("script");
      script.src = languageFile;
      script.async = true;
      script.onload = () => {
        if (callback) callback();
      };
      document.body.appendChild(script);
    } else {
      if (callback) callback();
    }
  };
  isScriptAlreadyIncluded = (src) => {
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++)
      if (scripts[i].getAttribute("src") === src) return true;
    return false;
  };

  save = (callback) => {
    if(this.props.setLod){
      this.props.setLod(true)
    }
    console.log("uploading image savesavesavesave", callback);
    // if (this.props.base64Handler !== "") {
    if (true) {
      // If base64Handler is specified
      console.log("handler is specifeiddddd");

      // Save all embedded base64 images first
      this.obj.saveImages(
        "",
        () => {
          console.log("Then save the content");
          // Then save the content
          let html = this.obj.html();

          if (callback) callback(html);
        },
        async (img, base64, filename) => {
          console.log("uploading image", filename);
          // Upload image process
          console.log(this.props.itemData)
          if(this.props.itemData){
            const sendData = {
              model: this.props.model,
              item: this.props.page_design,
              field: this.props.field_id,
              src: {
                file: base64,
                ext: filename.split(".").pop()
              }
            }
            const res = await PostData({url:`/application/${this.props.appId}/app_editor_file`, sendData})
            if(res && res.data){
              const getImg = await GetData({url:`/application/${this.props.appId}/app_editor_file/${res.data.id}`})
              if(getImg && getImg.data){
                img.setAttribute("src", get(getImg, "data.src.url"));
              }
            }
            
            return
          }
          apiClient({
            method: "post",
            url: "editor_file",
            data: {
              src: {
                file: base64,
                ext: filename.split(".").pop()
              },
              table: "website_page",
              field: "html",
              row_id: this.props.pageId
            }
          })
            .then(async (res) => {
              console.log("resresres upload", res);
              const id = get(res, "data.id");

              const imgSrc = await apiClient({
                method: "get",
                url: `editor_file/${id}`
              });
              console.log("imgURL", imgSrc);
              img.setAttribute("src", get(imgSrc, "data.src.url")); // set image src
            })
            .catch((err) => {
              console.log("eerrr", err);
            });
          // axios
          //   .post(this.props.base64Handler, {
          //     image: base64,
          //     filename: filename
          //   })
          //   .then((response) => {
          //     const uploadedImageUrl = response.data.url; // get saved image url

          //     img.setAttribute("src", uploadedImageUrl); // set image src
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        }
      );
    } else {
      let html = this.obj.html();

      if (callback) callback(html);
    }
  };

  saveContent = () => {
    this.save((html) => {
      this.props.onSave(html);
    });
  };

  saveContentAndFinish = () => {
    this.save((html) => {
      this.props.onSaveAndFinish(html);
    });
  };

  destroy = () => {
    this.obj.destroy();
  };

  render() {
    return <div className='is-container container'></div>;
  }
}

BuilderControl.defaultProps = {
  container: ".is-container",
  scriptPath: "/contentbuilder/",
  pluginPath: "/contentbuilder/",
  modulePath: "/assets/modules/",
  fontAssetPath: "/assets/fonts/",
  assetPath: "/assets/",
  imageSelect: "",
  fileSelect: "",
  base64Handler: "",
  largerImageHandler: "",
  snippetFile: "",
  languageFile: ""
};

export default BuilderControl;
