/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Button, message } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { session } from "../../session";
import { getDashboardLink } from "../../utils";
import config from "../../config";
import { useQueryParam , NumberParam, StringParam } from "use-query-params";
import { baseURL } from "../../config";
import { SyncOutlined } from '@ant-design/icons'
const LOCAL_STORAGE_KEY =
  config.stage === "dev" ? "onedaycloud-dev" : "onedaycloud";

const SignIn = () => {
  const history = useHistory();
  const [main_url] = useQueryParam("main_url", StringParam);
  const [field_id] = useQueryParam("field_id", NumberParam);
  const [model] = useQueryParam("model_id", NumberParam);
  const [reportTemplatesId] = useQueryParam("reportTemplatesId", NumberParam);
  const [page_design] = useQueryParam("page_design", NumberParam);
  const [appId] = useQueryParam("appId", NumberParam);
  const [s_id] = useQueryParam("s_id", StringParam );
  const [token] = useQueryParam("token", StringParam);
  const [lod , setLod] = useState(true)
  

  const onFinish = async (data) => {
    await session.signin(data);
  };

  const autoSignIn = async () => {
    setLod(true)
    try{
      var newUrl = main_url[main_url.length - 1] == "/" ? main_url.slice(0, -1) : main_url;
      var url = `/get_remote_site_session?token=${token}&s_id=${s_id}`
      const send = await fetch(`${newUrl}${url}`)
      const res = await send.json()
      if(!send.ok){
        if (res && res.meta && res.meta.errors) {
          Object.keys(res.meta.errors).map((key) => {
            message.error(`${[key]}: ${res.meta.errors[key]}`);
          });

          return null;
        }
      }

        if (res && res.meta && res.meta.message) {
          message.success(res.meta.message);

        }

       
       session.set('jwt' , res.data.jwt)
       session.set('s_id' , s_id)
       //session.set('user' , res.data)
    
       setTimeout(() => {
        if(reportTemplatesId){
          window.location.href = `/report-template?reportTemplatesId=${reportTemplatesId}&appId=${appId}`
          //history.push(`/report-template?reportTemplatesId=${reportTemplatesId}&appId=${appId}`);
         }else if(page_design){
          window.location.href = `/page-design?page_design_id=${page_design}&appId=${appId}&field_id=${field_id}&model_id=${model}`
         }else{
          window.location.href = '/'
          //history.push("/");
         }
       }, 1000);
      setLod(false)

    }catch(err){
      console.log(err)
    }

  }

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if(s_id && token && main_url){
      window.localStorage.setItem('main_url' , main_url)
      setTimeout(() => {
        autoSignIn()  
      }, 1000);
      
    }else{
      setLod(true)
    }

  }, [history]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "93vh",
        background: "#001529"
      }}
    >
      <SyncOutlined spin style={{color : 'white' , fontSize:'50px'}}/>
      {/* <div
        style={{
          background: "#fff",
          padding: "20px",
          borderRadius: "5px",
          minWidth: "400px"
        }}
      >
        <Form
          layout='vertical'
          name='signin'
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label='Username'
            name='username'
            rules={[
              {
                required: true,
                message: "Please input your username!"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: "Please input your password!"
              }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <Button type='primary' htmlType='submit'>
                Sign in
              </Button>
              <Button
                type='link'
                onClick={() => window.open(getDashboardLink("signup"))}
              >
                Sign up
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div> */}
    </div>
  );
};

export default SignIn;
