import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const PageMenuDropdown = ({ page, setPageModal, deletePage }) => {
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setPageModal(page);
        }}
      >
        Edit
      </Menu.Item>

      <Menu.Item danger onClick={() => deletePage(page)}>
        Delete
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <div>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};

export default PageMenuDropdown;
