const env = window.location.hostname.includes("sitebuilder.1daycloud.com")
  ? "prod"
  : "dev";
//const env = "prod";
const devConfig = {
  stage: "dev",
  baseURL: "https://testapi.1daycloud.com/v3",
};
const prodConfig = {
  stage: "prod",
  baseURL: "https://api.1daycloud.com/v3",
  // baseURL: 'http://52.34.142.67/v3'
};

module.exports =
  env === "prod" || process.env.REACT_APP_ENV === "prod"
    ? prodConfig
    : devConfig;
