import { Dropdown, Layout, Menu } from "antd";
import React, { useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import ReportBuilder from "../reportBuilder/reportBuilder";
import Home from "../Home/Home";
import SignIn from "../SignIn/SignIn";
import Website from "../Website/Website";
import { getDashboardLink } from "../../utils";
import {
  MenuOutlined,
  HomeOutlined,
  DashboardOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { session } from "../../session";
import PageDesign from "../pageDesign/pageDesign";

const { Header } = Layout;

const AppLayout = () => {
  const renderHeader = (
    <Header style={{ padding: "0 50px 0 0" }}>
      <div
        style={{
          display: "flex",
          height: "100%"
        }}
      >
        <Dropdown
          overlay={
            <Menu
              style={{
                float: "left"
              }}
            >
              <Menu.Item key='1-0' icon={<HomeOutlined />}>
                <Link to='/'>My Websites</Link>
              </Menu.Item>
              <Menu.Item key='1-1' icon={<DashboardOutlined />}>
                <a href={getDashboardLink("")}>Back to dashboard</a>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key='1-2'
                icon={<LogoutOutlined />}
                onClick={() => session.signout()}
              >
                Sign out
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <span
            style={{
              // height: "100%",
              display: "flex",
              width: "50px"
            }}
          >
            <MenuOutlined
              style={{
                margin: "auto",
                float: "left",
                color: "#fff"
              }}
              twoToneColor='#fff'
            />
          </span>
        </Dropdown>
        <div>
          <Link to='/'>
            <img
              src='https://res.cloudinary.com/dtxaita6x/image/upload/v1553081717/cloud.png'
              alt='oneday cloud'
              style={{
                maxWidth: "30px",
                transform: "translateY(-3px)"
              }}
            />
            <span
              style={{
                fontWeight: 600,
                fontSize: "15px",
                color: "#fff",
                margin: "0 20px",
                opacity: 0.5
              }}
            >
              OneDay Cloud | Website Builder
            </span>
          </Link>
        </div>
      </div>
    </Header>
  );

  // useEffect(() => {
  //    session.set('jwt', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJsTlwvQWoyaytJR21ramJnUThKMnF6ZEhyWmFsWlwvb0Z2dDdtaUhpNm0waUU9IiwiaWF0IjoxNjkzOTM5NTcyLCJleHAiOjE2OTM5NDU1NzIsImlzcyI6ImFwaS4xZGF5Y2xvdWQuY29tIiwic3ViIjoyMzU2LCJzY29wZSI6Imp3dCIsInBocHNpZCI6ImRuZ2dwcmE3a3N1ZDZrMzBxMWp1YTI5cTQzIiwicmVuZXciOmZhbHNlfQ.VotOdP3ilvpxRyYcvhCTjAK_JA5t0VtrmAEK5713cKtZJwZGvZbYdp53lqxBWiz8dYeRnm2CfPAIuSiDh0GgyD_gzv9BtCioCNM96FlaaUphXkGHmGJqdc2TdOPR1Qz9PC6ETi10bAzufLazH3G4YkZkppcPnLVe0zo3yXjKuI8bItha_Yq07j3WJ1P-TLb59MHZNB6FcFCjX-d6TOrWaE_3r7V_qPLMDrZNs1dk5spa4ouGVOArtL8_nhPw3A1MohuhlRBXqWi9j7XE8Kq-Pvzi0NYFGAJ5p6u50_iU5eeUKwepS-CyGjM-_rNtn6XvH5buVdHXjCq3k0Yu4JdzcQ")
  //    session.set('s_id', 'dnggpra7ksud6k30q1jua29q43')
  // },[])

  return (
    <Layout>
      {renderHeader}
      <Switch>
        <Route exact path='/signin'>
          <SignIn />
        </Route>
        <PrivateRoute path="/page-design" exact component={PageDesign} />
        <PrivateRoute path="/report-template" exact component={ReportBuilder} />
        <PrivateRoute path='/:websiteId=:websiteDomain' component={Website} />
        <PrivateRoute path='/' component={Home} />
      </Switch>
    </Layout>
  );
};

export default AppLayout;
