const initialData = `<div class="row clearfix">
<div class="column full">
    <h2 class="size-32" style="text-align: center; font-weight: 400;">Welcome to OneDay Website Builder</h2>
</div>
</div>
<div class="row clearfix">
<div class="column full" data-noedit="">
    <div class="spacer height-40"></div>
</div>
</div>
<div class="row clearfix">
<div class="column half">
    <img src="https://res.cloudinary.com/dtxaita6x/image/upload/v1623617079/office2.png" alt="">
</div><div class="column half">
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
</div>
</div>`

export default initialData