/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "antd";
import React, { useEffect } from "react";
import { useQueryParam, BooleanParam, NumberParam } from "use-query-params";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import "./ViewPage.css";
import { useParams } from "react-router-dom";

export default function ViewPage({ pageHtml }) {
  const [, setEdit] = useQueryParam("edit", BooleanParam);
  const [pageId] = useQueryParam("pageId", NumberParam);
  const { websiteDomain } = useParams();
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    // Clear

    // Initial sample (initial) content
    let html = pageHtml;

    // Render html content
    let range = document.createRange();
    const wrapper = document.querySelector(".container");
    wrapper.innerHTML = "";
    wrapper.appendChild(range.createContextualFragment(html)); // We use createContextualFragment so that embedded javascript code (code block) will be executed
  }

  return (
    <div>
      <div className='panel-home is-cms'>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            icon={<EditOutlined />}
            className='is-btn'
            onClick={() => setEdit(true)}
          >
            Edit
          </Button>
          <Button
            icon={<EyeOutlined />}
            className='is-btn'
            onClick={() => window.open(`/view/${websiteDomain}/${pageId}`)}
          >
            View Page
          </Button>
        </div>
      </div>

      <div className='is-container container'></div>
    </div>
  );
}
