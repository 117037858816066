import React , {useEffect , useState , useRef} from "react";
import './styled.css'
import { apiClient } from "../../api";
import ReactDOMServer from 'react-dom/server';
import BuilderControl from "../contentbuilder/buildercontrol";
import { useHistory } from "react-router";
import { useQueryParam , BooleanParam , NumberParam} from "use-query-params";
import { PostData , GetData } from "../../api";
import { Spin } from "antd";
import { GetFirstKey } from "../../utils";

const PageDesign = () => {
    const callSave = useRef();
    const callDestroy = useRef();
    const callSaveAndFinish = useRef();
    const [field_id] = useQueryParam("field_id", NumberParam);
    const [model] = useQueryParam("model_id", NumberParam);
    const [page_design] = useQueryParam("page_design_id", NumberParam);
    const [appId] = useQueryParam("appId", NumberParam);
    const [lod , setLod] = useState(false)
    const [saveLod , setSaveLod] = useState(false)
    const [html , setHtml] = useState(false)
    const history = useHistory()

    const defaultHtml = '<div class="row clearfix"><div class="column half"><img src="assets/minimalist-blocks/images/jon-lalin-731093-unsplash-(1)-tdmMt1.jpg" alt=""></div><div class="column half"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p></div></div>'

    const handleOnSave = async (html) => {
  
        console.log(html , 'start')
        const sendData = {
          [field_id] : html
        }
        var url = `/application/${appId}/item_data/${page_design}?ignore_container=1&model=${model}`
        await PostData({url , sendData , method:'PUT'}).then((res) => {
          if(res){
            
          }
        })
        setSaveLod(false)
    }

    const handleOnSaveAndFinish = (e) =>{
        console.log(e , 'Done')
    }
    const closeBuilder = () => {
      history.push('/')
    }

    const getPageDesign = async () => {
      setLod(true)
      const res = await GetData({url: `/application/${appId}/item_data/${page_design}?by_field_code=1&ignore_container=1&fields_structure_only=1`})
      if(res && res.data){
        setHtml(res.data[field_id] || defaultHtml)
      }
      setLod(false)
    }
  
      useEffect(() => {
        
        return () => {
          if(callDestroy.current)
          callDestroy.current()
        }
      },[])
  //signin?appId=3&page_design=451
      useEffect(() => {
        if(!page_design || !appId || !field_id || !model){
          //history.push('/')
        }else{
          getPageDesign()
        }
      },[])

    return(
        lod ? <div style={{minHeight:'500px' , display:'flex',justifyContent:'center', marginTop:'50px'}}><Spin size="large"/></div> : html &&
        <>
        <BuilderControl
        // pageId={"26"}
        itemData={true}
        appId={appId}
        model={model}
        page_design={page_design}
        field_id={field_id}
        setLod={setSaveLod}
        history={history}
        pageHtml={html}
        onSave={handleOnSave}
        onSaveAndFinish={handleOnSaveAndFinish}
        doSave={(f) => callSave.current = f  }
        doSaveAndFinish={(f) => callSaveAndFinish.current = f}
        doDestroy={(f) => callDestroy.current = f}
        base64Handler={"/upload"}
        largerImageHandler={"/upload"}
        imageSelect={"images.html"}
        snippetFile={"/assets/minimalist-blocks/content.js"}
        languageFile={"/contentbuilder/lang/en.js"}
      />
       <div
          className='is-ui'
          style={{
            position: "fixed",
            right: "30px",
            bottom: "30px",
            display: "flex",
            zIndex: "10",
            background: "white"
          }}
        >
          <button
            type='button'
            onClick={() => {callSave.current()}}
            style={{ width: "85px", backgroundColor: "rgba(0,0,0,0.06)" , display:'flex' , justifyContent:'center' , gap:'10px'}}
          >
            {saveLod && <Spin size="small"/>}
            Save
          </button>
          {/* <button
            type='button'
            onClick={closeBuilder}
            style={{ width: "85px", backgroundColor: "rgba(0,0,0,0.06)" }}
          >
            Close
          </button> */}
        </div>
        </>
    )
}

export default PageDesign