import { apiClient } from "./api";
import { setGlobalLoading } from "./utils";
import { get } from "lodash";
import config from "./config";
const queryString = require("query-string");
const search = queryString.parse(window.location.search);
const LOCAL_STORAGE_KEY =
  config.stage === "dev" ? "onedaycloud-dev" : "onedaycloud";
class Session {
  constructor() {
    /**
     * To reduce JSON.parse calls, store/read .get values here
     */
    this.cache = {};

    if (get(search, "s_id")) {
      this.set("s_id", get(search, "s_id"));
    }
  }
  async signin(data) {
    try {
      setGlobalLoading(true);
      const res = await apiClient({
        method: "post",
        url: "sign_in",
        data,
      });
      this.set("user", res.data);

      if (res.meta && res.meta.token) {
        this.set("jwt", res.meta.token);
      }
      setGlobalLoading(false);
      window.location.replace("/");
    } catch (err) {
      setGlobalLoading(false);
    }
  }
  async signout() {
    try {
      setGlobalLoading(true);
      await apiClient({
        method: "post",
        url: "sign_out",
      });
      this.destroy();
    } catch (err) {}
    setGlobalLoading(false);
  }
  /**
   * Returns a boolean to indicate whether the user is authenticated
   */
  isLoggedIn() {
    const sessionId = this.get("s_id");

    if (!sessionId) {
      return false;
    }

    return true;
  }
  /**
   * If you have user information, and you are logged in, then you're good to go
   */
  isAccountSetup() {
    // const user = this.get('user')

    if (this.isLoggedIn()) {
      return true;
    }

    return false;
  }

  /**
   * Get a property from the user session
   */
  get(key) {
    if (this.cache[key]) {
      return this.cache[key];
    }

    let sessionData = localStorage.getItem(LOCAL_STORAGE_KEY) || {};
    sessionData =
      typeof sessionData === "string" ? JSON.parse(sessionData) : sessionData;

    const value = sessionData[key] || null;

    this.cache[key] = value;

    return value;
  }
  set(key, val) {
    // Only save these keys
    const allowedKeys = ["s_id", "user", "jwt"];

    if (allowedKeys.indexOf(key) < 0) {
      throw new Error(
        `Invalid session property: ${key}.  Please don't pollute user sessions.  Every User has a "Meta" object where custom data can be stored in the database.  Use that instead.`
      );
    }

    const sessionData = this.getAll();
    sessionData[key] = val;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sessionData));

    return sessionData;
  }
  /**
   * Get all data from the user session
   */
  getAll() {
    let sessionData = localStorage.getItem(LOCAL_STORAGE_KEY) || {};
    return typeof sessionData === "string"
      ? JSON.parse(sessionData)
      : sessionData;
  }
  /**
   * Destroy browser session
   */
  destroy() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    this.cache = {};
    window.location.replace("/signin");
  }
}
export const session = new Session();
