import React , {useEffect , useState , useRef} from "react";
import './styled.css'
import { apiClient } from "../../api";
import ReactDOMServer from 'react-dom/server';
import BuilderControl from "../contentbuilder/buildercontrol";
import { useHistory } from "react-router";
import { useQueryParam , BooleanParam , NumberParam} from "use-query-params";
import { PostData , GetData } from "../../api";
import { Spin } from "antd";
const ReportBuilder = () => {
    const callSave = useRef();
    const callDestroy = useRef();
    const callSaveAndFinish = useRef();
    const [reportTemplatesId] = useQueryParam("reportTemplatesId", NumberParam);
    const [appId] = useQueryParam("appId", NumberParam);
    const [lod , setLod] = useState(false)
    const [saveLod , setSaveLod] = useState(false)
    const [html , setHtml] = useState(false)
    // router_name: "test"
    // website : "21"
    // api name website_page
    // get wepsite website=21&&is_active[is_not_null]
    // get page website_page/28
    const history = useHistory();


    const handleOnSave = async (html) => {
  
        console.log(html , 'start')
        const sendData = {
          html_content : html
        }
        var url = `/application/${appId}/print_template/${reportTemplatesId}`
        await PostData({url , sendData , method:'PUT'}).then((res) => {
          if(res){
            
          }
        })
        setSaveLod(false)
    }

    const handleOnSaveAndFinish = (e) =>{
        console.log(e , 'Done')
    }
    const closeBuilder = () => {
      history.push('/')
    }
    const defaultHtml = '<div class="row clearfix"><div class="column half"><img src="assets/minimalist-blocks/images/jon-lalin-731093-unsplash-(1)-tdmMt1.jpg" alt=""></div><div class="column half"><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p></div></div>'
    ///report-template?reportTemplatesId=3&appId=1419
    const getReportTemplate = async () => {
      setLod(true)
      var url = `/application/${appId}/print_template/${reportTemplatesId}`
      await GetData({url}).then((res) => {
        if(res && res.data){
          setHtml(res.data.html_content || defaultHtml)
        }else{
          setHtml(null)
        }
      })
      setLod(false)
    }

    useEffect(() => {
      
      return () => {
        callDestroy.current()
      }
    },[])

    useEffect(() => {
      if(!reportTemplatesId || !appId){
        history.push('/')
      }else{
        getReportTemplate()
      }
    },[])

    return(
        lod ? <div style={{minHeight:'500px' , display:'flex',justifyContent:'center', marginTop:'50px'}}><Spin size="large"/></div> : html &&
        <>
        <BuilderControl
        // pageId={"26"}
        setLod={setSaveLod}
        history={history}
        pageHtml={html}
        onSave={handleOnSave}
        onSaveAndFinish={handleOnSaveAndFinish}
        doSave={(f) => callSave.current = f  }
        doSaveAndFinish={(f) => callSaveAndFinish.current = f}
        doDestroy={(f) => callDestroy.current = f}
        base64Handler={"/upload"}
        largerImageHandler={"/upload"}
        imageSelect={"images.html"}
        snippetFile={"/assets/minimalist-blocks/content.js"}
        languageFile={"/contentbuilder/lang/en.js"}
      />
       <div
          className='is-ui'
          style={{
            position: "fixed",
            right: "30px",
            bottom: "30px",
            display: "flex",
            zIndex: "10",
            background: "white"
          }}
        >
          <button
            type='button'
            onClick={() => {callSave.current()}}
            style={{ width: "85px", backgroundColor: "rgba(0,0,0,0.06)" , display:'flex' , justifyContent:'center' , gap:'10px'}}
          >
            {saveLod && <Spin size="small"/>}
            Save
          </button>
          {/* <button
            type='button'
            onClick={() => { callSaveAndFinish.current() }}
            style={{ width: "120px", backgroundColor: "rgba(0,0,0,0.06)" }}
          >
            Save & Finish
          </button> */}
          <button
            type='button'
            onClick={closeBuilder}
            style={{ width: "85px", backgroundColor: "rgba(0,0,0,0.06)" }}
          >
            Close
          </button>
        </div>
        </>
    )
}

export default ReportBuilder


// const ReportBuilder = () => {
//     // https://testapi.1daycloud.com/v3/website_page/26
//     const [NewReport , setNewReport] = useState(null)
//     const [data , setData] = useState([
//         {name : 'bilal' , id : 1 , mobile : 12345679},
//         {name : 'noor' , id : 2 , mobile : 987654321}
//     ])
//     const [clo , setClo] = useState([
//         {cloName : 'Name' , dataname : 'name'},
//         {cloName : 'Id' , dataname : 'id'},
//         {cloName : 'Mobile' , dataname : 'mobile'},
//     ])

//     const TableData = ReactDOMServer.renderToStaticMarkup(
//         <table className="CTABLE">
//         <thead>
//         <tr className="tablehead">
//             {clo.map((cloItem , index) => {
                
//                 return(
//                     <th className="tableTH" key={index}>{cloItem.cloName}</th>
//                 )
//             })}

//         </tr>
//         </thead>
//         <tbody>
//                 {data.map((item , index) => {
//                     return(
//                         <tr className="tablebdyrt" key={index}>
//                         {clo.map((cloName , index) => {
//                             return(
//                                 <td className="tabletd" key={index}>
//                                     <p className="tableLine"> {item[cloName.dataname]}</p>
//                             </td>
//                             )
//                         })}
//                         </tr>
//                     )
//                 })}
//         </tbody>
//         </table>
//         )

//         const Header = ReactDOMServer.renderToStaticMarkup(
//             <div className="header">
//                 <img src={''}/>
//                 <h4>test 1</h4>
//                 <h4>test 2</h4>
//                 <h4>test 3</h4>
//                 <h4>test 4</h4>
//             </div>
//         )

//         const Footer = ReactDOMServer.renderToStaticMarkup(
//             <div className="header">
//                 just for testing
//             </div>
//         )

//         var stringToHTML = function (str) {
//             var dom = document.createElement('div');
//             dom.innerHTML = str;
//             return dom;
        
//         };

//     const getData = async () => {
//         const res = await apiClient({ url : 'website_page/27' })
//         var {data} = res
//         var dataHtml = data.html
//         var doc = stringToHTML(dataHtml)
//             if(doc.querySelector('#header')){
//                 var ele = doc.querySelector('#header')
//                 ele.innerHTML = Header
//             }
//             if(doc.querySelector('#table')){
//                 var ele = doc.querySelector('#table')
//                 ele.innerHTML = TableData
//             }

//             if(doc.querySelector('#footer')){
//                 var ele = doc.querySelector('#footer')
//                 ele.innerHTML = Footer
//             }
//         setNewReport(doc)
//         var ReportBox = document.getElementById('report')
//         if(ReportBox){
//             ReportBox.appendChild(doc)
//         }
//     }

//     useEffect(() => {
//         getData()
//     },[])
//     return(
//         <div id={'report'} >
        
//         </div>
//     )
// }

// export default ReportBuilder
