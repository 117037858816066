/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumb, Input, Menu, Modal, Switch } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, useState } from "react";
import {
  FileOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined
} from "@ant-design/icons";
import useSWR, { mutate } from "swr";
import { apiClient } from "../../api";
import { get } from "lodash";
import { setGlobalLoading } from "../../utils";
import PageMenuDropdown from "../PageMenuDropdown/PageMenuDropdown";
import { useQueryParam, NumberParam, BooleanParam } from "use-query-params";
import PageBuilder from "../PageBuilder/PageBuilder";
import SpinWrapper from "../common/SpinWrapper";
import { useParams } from "react-router";
import EmptyData from "../common/EmptyData";

const { confirm } = Modal;

const Website = () => {
  const [pageId, setPageId] = useQueryParam("pageId", NumberParam);
  const [, setEdit] = useQueryParam("edit", BooleanParam);

  const { websiteId, websiteDomain } = useParams();
  const [pageModal, setPageModal] = useState(false);
  const apiString = `website_page?website=${websiteId}&&is_active[is_not_null]`;

  const { data, error } = useSWR(apiString, () =>
    apiClient({
      method: "get",
      url: apiString
    })
  );

  const pages = get(data, "data", []);
  useEffect(() => {
    if (pages.length && !pageId) {
      setPageId(pages[0].id);
    }
    return () => {};
  }, []);
  const reload = () => mutate(apiString);

  const loadingPages = !data && !error;

  const closePageModal = () => {
    reload();
    setPageModal(false);
  };
  const submitModal = async () => {
    setGlobalLoading(true, "newPage");
    try {
      // If editing page the put
      if (get(pageModal, "id")) {
        await apiClient({
          method: "put",
          url: `website_page/${get(pageModal, "id")}`,
          data: JSON.stringify({
            website: websiteId,
            router_name: get(pageModal, "router_name"),
            is_active: get(pageModal, "is_active")
          })
        });
      } else {
        const res = await apiClient({
          method: "post",
          url: `website_page`,
          data: JSON.stringify({
            website: websiteId,
            router_name: get(pageModal, "router_name"),
            is_active: get(pageModal, "is_active")
          })
        });
        setPageId(get(res, "data.id"));
      }

      closePageModal();
    } catch (e) {
      // setGlobalErrorMessage(e);
    }
    setGlobalLoading(false, "newPage");
  };

  const deletePage = async (page) => {
    confirm({
      title: `Are you sure delete this page ${get(page, "router_name")}`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await apiClient({
          method: "delete",
          url: `website_page/${page.id}`
        });
        reload();
        setPageId(null);
        setEdit(false);
      },
      onCancel() {}
    });
  };
  return (
    <Layout>
      <Sider width={200} className='site-layout-background'>
        {loadingPages ? (
          <SpinWrapper />
        ) : (
          <Menu
            mode='inline'
            defaultSelectedKeys={["pages"]}
            defaultOpenKeys={["pages"]}
            style={{ height: "100%", borderRight: 0 }}
          >
            <SubMenu key='pages' icon={<FileOutlined />} title='Pages'>
              {pages &&
                pages.map((page) => (
                  <Menu.Item
                    key={page.id}
                    onClick={() => {
                      setPageId(page.id);
                      setEdit(false);
                    }}
                  >
                    <span
                      // to={`/${page.website}/pages/${page.id}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <span>{page.router_name} </span>
                      <PageMenuDropdown
                        page={page}
                        setPageModal={setPageModal}
                        deletePage={deletePage}
                      />
                    </span>
                  </Menu.Item>
                ))}
            </SubMenu>
            <Menu.Item
              key='add-page'
              icon={<PlusCircleOutlined />}
              onClick={() => setPageModal(true)}
            >
              Add Page
            </Menu.Item>
          </Menu>
        )}
      </Sider>
      <Layout style={{ padding: "0 24px 24px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>{websiteDomain}</Breadcrumb.Item>
          {pageId && (
            <Breadcrumb.Item>
              {get(
                pages.find((page) => page.id === pageId),
                "router_name"
              )}
              <Breadcrumb.Item
                onClick={() => window.open(`/view/${websiteDomain}`)}
                style={{ float: "right" }}
              >
                <EyeOutlined style={{ marginRight: "5" }} />
                View website
              </Breadcrumb.Item>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
        <Modal
          title='Page Details'
          visible={!!pageModal}
          onOk={submitModal}
          onCancel={closePageModal}
        >
          <h4>Page Name</h4>
          <Input
            placeholder='about-us'
            value={pageModal && pageModal.router_name}
            onChange={(e) =>
              setPageModal({
                ...(pageModal || {}),
                router_name: e.target.value
                  .toLowerCase()
                  .replace(/[^a-z0-9_]+/g, "-")
                  .replace(/^-+/, "")
              })
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                submitModal();
              }
            }}
          />
          <br />
          <br />
          <h4>Is Active</h4>
          <Switch
            defaultChecked
            checked={get(pageModal, "is_active", true)}
            onChange={() =>
              setPageModal({
                ...(pageModal || {}),
                is_active: !get(pageModal, "is_active", true)
              })
            }
          />
        </Modal>
        <Content
          className='site-layout-background'
          style={{
            padding: 24,
            margin: 0,
            minHeight: "100vh",
            background: "#fff"
          }}
        >
          {!pageId ? (
            <EmptyData
              description={`Please ${
                !loadingPages && !pages.length ? "create" : "select"
              } a page from the side bar menu.`}
            />
          ) : (
            <PageBuilder />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Website;
