import { Spin } from "antd";
import React from "react";

const SpinWrapper = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "250px",
        width: "100%"
      }}
    >
      <span>
        <Spin />
      </span>
    </div>
  );
};

export default SpinWrapper;
