import { Link } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { GetData, apiClient } from "../../api";
import { get } from "lodash";
import SpinWrapper from "../common/SpinWrapper";

import { useEffect, useState } from "react";
import { DownOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { Button, Dropdown, Input, Switch, Menu, Modal } from "antd";
import {
  PlusCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { setGlobalLoading } from "../../utils";
import EmptyData from "../common/EmptyData";
const { confirm } = Modal;
const Home = () => {
  let apiString = `website?api_order[creation_date]=desc&is_active[is_not_null]`;
  /**
   * Get all websites from API
   */
  const [data , setData] = useState(null)
  const [lod , setLod] = useState(true)

  const getWebsites = async () => {
    setLod(true)
    const res = await GetData({url: `/website?api_order[creation_date]=desc&is_active[is_not_null]` })
    if(res){
      setData(res)
    }
    setLod(false)
  }
  
  useEffect(() => {
    getWebsites()
  },[])

  const reload = () => mutate(apiString);
  const [websiteModal, setWebsiteModal] = useState(false);
  const websites = get(data, "data");

  const loading = !data && lod;
  /**
   * Submit form
   */
  const submitModal = async () => {
    setGlobalLoading(true, "newWebsite");
    try {
      /**
       * If editing website them put
       */
      if (get(websiteModal, "id")) {
        await apiClient({
          method: "put",
          url: `website/${get(websiteModal, "id")}`,
          data: JSON.stringify({
            domain_name: get(websiteModal, "domain_name"),
            is_active: get(websiteModal, "is_active")
          })
        });
      } else {
        await apiClient({
          method: "post",
          url: `website`,
          data: JSON.stringify({
            domain_name: get(websiteModal, "domain_name")
          })
        });
      }

      closePageModal();
    } catch (e) {}
    setGlobalLoading(false, "newWebsite");
  };
  const closePageModal = () => {
    setWebsiteModal(false);
    mutate(apiString);
  };

  const handleDeleteWesite = async (website) => {
    confirm({
      title: `Are you sure delete this website ${get(website, "domain_name")}`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await apiClient({
          method: "delete",
          url: `website/${website.id}`
        });
        reload();
      },
      onCancel() {}
    });
  };
  let spanStyles = {
    color: "black",
    position: "absolute",
    bottom: "10px",
    opacity: 0.2
  };
  return (
    <div
      style={{
        padding: "50px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button
          type='primary'
          icon={<PlusCircleOutlined />}
          onClick={() => setWebsiteModal(true)}
        >
          Create New Website
        </Button>
        <Modal
          title='Website Details'
          visible={!!websiteModal}
          onOk={submitModal}
          onCancel={closePageModal}
        >
          <h4>Domain Name</h4>
          <Input
            placeholder='example.com'
            value={get(websiteModal, "domain_name", "")}
            onChange={(e) =>
              setWebsiteModal({
                ...(websiteModal || {}),
                domain_name: e.target.value
                  .toLowerCase()

                  .replace(/[^a-z0-9._]+/g, "-")
                  .replace(/^-+/, "")
              })
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                submitModal();
              }
            }}
          />
          <br />
          <br />
          <h4>Is Active</h4>
          <Switch
            defaultChecked
            checked={get(websiteModal, "is_active", true)}
            onChange={() =>
              setWebsiteModal({
                ...(websiteModal || {}),
                is_active: !get(websiteModal, "is_active", true)
              })
            }
          />
        </Modal>
      </div>
      <br />

      <div
        style={{
          minHeight: "70vh",
          display: "flex"
        }}
      >
        {loading ? (
          <SpinWrapper />
        ) : websites && websites.length ? (
          <div
            gutter={16}
            style={{
              width: "100%",
              margin: "auto 0",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "50px"
            }}
          >
            {websites.map((website) => (
              <span
                key={website.id}
                style={{
                  position: "relative",
                  background: "#fff",
                  borderRadius: "5px",

                  textAlign: "center",
                  display: "flex"
                }}
              >
                <span
                  style={{
                    ...spanStyles,
                    padding: "0",
                    right: "10px",
                    top: "10px",
                    zIndex: "10"
                  }}
                >
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item
                          key='edit'
                          onClick={() => {
                            setWebsiteModal(website);
                          }}
                          icon={<EditOutlined />}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          danger
                          key='delete'
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            handleDeleteWesite(website);
                          }}
                        >
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    {/* <span style={{ position: "relative" }}>
                    </span> */}
                    <DownOutlined />
                  </Dropdown>
                </span>
                {/* <Button
                  type='link'
                  onClick={(e) => {
                    e.stopPropagation();
                    setWebsiteModal(website);
                  }}
                  style={{
                    ...spanStyles,
                    padding: "0",
                    right: "10px",
                    top: "10px",
                    zIndex: "10"
                  }}
                >
                  Edit
                </Button> */}
                <Link
                  to={`/${website.id}=${website.domain_name}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "50px"
                  }}
                >
                  <h1
                    style={{
                      fontSize: "23px"
                    }}
                  >
                    {website.domain_name}
                  </h1>
                  <span style={{ ...spanStyles, left: "10px", opacity: 1 }}>
                    <span style={{ opacity: spanStyles.opacity }}>
                      Active :{" "}
                    </span>
                    {website.is_active ? (
                      <CheckCircleTwoTone twoToneColor='#52c41a' />
                    ) : (
                      <CloseCircleTwoTone twoToneColor='#eb2f96' />
                    )}
                  </span>
                  <span style={{ ...spanStyles, right: "10px" }}>
                    Created At : {website.creation_date.split(" ")[0]}
                  </span>
                </Link>
              </span>
            ))}
          </div>
        ) : (
          <EmptyData description='Please create a new website' />
        )}
      </div>
    </div>
  );
};

export default Home;
