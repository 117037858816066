import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { QueryParamProvider } from 'use-query-params';
import AppLayout from "./components/AppLayout/AppLayout.jsx";
import './content.css';
import './ionicons/css/ionicons.min.css';
import 'antd/dist/antd.css';
import ViewWebsite from './components/ViewWebsite/ViewWebsite.jsx';
ReactDOM.render(
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route path='/view/:websiteDomain' exact component={ViewWebsite} />
        <Route path='/view/:websiteDomain/:pageId' exact component={ViewWebsite} />
        <Route path='/' component={AppLayout} />
      </Switch>
      
    </QueryParamProvider>
    </Router>,
  document.getElementById('root')
);
