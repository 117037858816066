/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import EditPage from "../EditPage/EditPage.jsx";
import ViewPage from "../ViewPage/ViewPage.jsx";
import { useQueryParam, NumberParam, BooleanParam } from "use-query-params";
import { apiClient } from "../../api";
import { get } from "lodash";
import SpinWrapper from "../common/SpinWrapper";
import initialData from "../../initialData";
import useSWR, { mutate } from "swr";
import { usePrevious } from "react-use";

const PageBuilder = () => {
  const [pageNewHtml, setPageNewHtml] = useState("");

  const [pageId] = useQueryParam("pageId", NumberParam);
  const [edit, setEdit] = useQueryParam("edit", BooleanParam);

  const history = useHistory();
  const previousPageId = usePrevious(pageId);
  const apiString = pageId && `website_page/${pageId}`;
  const { data, error, isValidating } = useSWR(apiString, () =>
    apiClient({
      method: "get",
      url: `website_page/${pageId}`
    })
  );
  const loading = pageId && !data && !error;
  const page = get(data, "data", {});
  const pageHtml = get(page, "html");

  useEffect(() => {
    setPageNewHtml("");
    return () => {};
  }, [pageId]);

  const html = pageNewHtml || pageHtml || initialData;
  const reloadPage = () => {
    mutate(apiString);
  };

  if (loading || (isValidating && !html) || previousPageId !== pageId) {
    return <SpinWrapper />;
  }

  return (
    <div>
      {!edit ? (
        <ViewPage pageHtml={html} />
      ) : (
        <EditPage
          history={history}
          pageId={pageId}
          pageHtml={html}
          setEdit={setEdit}
          reloadPage={reloadPage}
          setPageNewHtml={setPageNewHtml}
        />
      )}
    </div>
  );
};

export default PageBuilder;
