import { Empty } from "antd";

const EmptyData = ({ description }) => {
  return (
    <div
      style={{
        minHeight: "400px",
        display: "flex",
        margin: "auto"
      }}
    >
      <span
        style={{
          margin: "auto"
        }}
      >
        <Empty description={description} />
      </span>
    </div>
  );
};

export default EmptyData;
