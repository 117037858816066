import React, { Component } from "react";
import { apiClient } from "../../api";
import BuilderControl from "../contentbuilder/buildercontrol";
import { setGlobalLoading } from "../../utils";
export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      html: this.props.pageHtml || ""
    };

    this.history = props.history;

    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnSaveAndFinish = this.handleOnSaveAndFinish.bind(this);
  }

  async savePage(html) {
    setGlobalLoading(true);

    await apiClient({
      method: "put",
      url: `website_page/${this.props.pageId}`,
      data: {
        html
      }
    });
    setGlobalLoading(false);
    this.props.setPageNewHtml(html);
  }
  async handleOnSave(html) {
    // Save content
    this.savePage(html);
  }

  async handleOnSaveAndFinish(html) {
    // Save content
    await this.savePage(html);
    this.props.setEdit(false);
  }

  componentWillUnmount() {
    if (this.callDestroy) this.callDestroy();
  }

  closeBuilder = () => {
    const answer = window.confirm("Do you really want to leave?");
    // cancel the navigation and stay on the same page
    if (!answer) return false;

    this.props.setEdit(false);
  };

  render() {
    return (
      <>
        <BuilderControl
          pageId={this.props.pageId}
          history={this.history}
          pageHtml={this.state.html}
          onSave={this.handleOnSave}
          onSaveAndFinish={this.handleOnSaveAndFinish}
          doSave={(f) =>
            (this.callSave = f)
          } /* https://stackoverflow.com/questions/37949981/call-child-method-from-parent */
          doSaveAndFinish={(f) => (this.callSaveAndFinish = f)}
          doDestroy={(f) => (this.callDestroy = f)}
          base64Handler={"/upload"}
          largerImageHandler={"/upload"}
          imageSelect={"images.html"}
          snippetFile={"/assets/minimalist-blocks/content.js"}
          languageFile={"/contentbuilder/lang/en.js"}
        />

        <div
          className='is-ui'
          style={{
            position: "fixed",
            right: "30px",
            bottom: "30px",
            display: "flex",
            zIndex: "10",
            background: "white"
          }}
        >
          <button
            type='button'
            onClick={() => this.callSave()}
            style={{ width: "85px", backgroundColor: "rgba(0,0,0,0.06)" }}
          >
            Save
          </button>
          <button
            type='button'
            onClick={() => this.callSaveAndFinish()}
            style={{ width: "120px", backgroundColor: "rgba(0,0,0,0.06)" }}
          >
            Save & Finish
          </button>
          <button
            type='button'
            onClick={() => this.closeBuilder()}
            style={{ width: "85px", backgroundColor: "rgba(0,0,0,0.06)" }}
          >
            Close
          </button>
        </div>
      </>
    );
  }
}
