import { message } from "antd";
import { get } from "lodash";

export const setGlobalLoading = (loading, key) => {
  if (loading) {
    message.loading({ content: "Loading", duration: 0, key });
  } else {
    message.destroy(key);
  }
};
export const getDashboardLink = (path) =>
  `https://dashboard.1daycloud.com/${path}`;

export const setGlobalErrorMessage = (error) => {
  try {
    const { data } = error.response || {};
    const errors = Object.values(get(data, "meta.errors", {}));

    //message.error({content: errors.join(', '), key: errors.join(', ')}, 15)
    if (errors) {
      Object.keys(errors).map((key) => {
        message.error(`${key}: ${errors[key]} `);
      });
    }
  } catch (e) {
    message.error("Something went wrong!", 15);
  }
};

export const GetFirstKey = (obj) => {
  return Object.keys(obj)[0];
};
