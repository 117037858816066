/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Route, Redirect } from "react-router-dom";

import { session } from "../../session";

export const PrivateRoute = ({ userCookie, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        session.isAccountSetup() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin"
            }}
          />
        )
      }
    />
  );
};
