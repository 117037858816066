import axios from "axios";
import { get } from "lodash";
import { baseURL } from "./config";
import { setGlobalErrorMessage } from "./utils";
import { session } from "./session";
import { message } from "antd";

var newUrl = window.localStorage.getItem("main_url") || "";

if (newUrl) {
  newUrl = newUrl[newUrl.length - 1] == "/" ? newUrl.slice(0, -1) : newUrl;
}

export const apiClient = axios.create({
  baseURL: newUrl,
  headers: {
    "Content-Type": "application/json",
    s_id: session.get("s_id"),
    jwt: session.get("jwt"),
  },
});

// Add a response interceptor

apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx

    if (response && get(response, "headers.s_id")) {
      session.set("s_id", get(response, "headers.s_id"));
    }
    if (response && response.data && response.data.meta) {
      if (session.get("jwt") !== response.data.meta.token) {
        session.set("jwt", response.data.meta.token);
      }
    }

    return response?.data;
  },
  async function (error) {
    setGlobalErrorMessage(error);
    const isLoggedIn = get(error, "response.data.meta.user_logged_in");
    if (!isLoggedIn) {
      // await session.signout()
    }

    return Promise.reject(error);
  }
);

export const PostData = async ({
  url,
  method,
  sendData,
  sendRes,
  sendErrorRes,
}) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      s_id: session.get("s_id"),
      jwt: session.get("jwt"),
    };
    const send = await fetch(`${newUrl}${url}`, {
      headers,
      method: method || "POST",
      body: JSON.stringify(sendData),
    });
    const res = await send.json();
    if (!send.ok) {
      if (res && res.meta && res.meta.errors) {
        Object.keys(res.meta.errors).map((key) => {
          message.error(`${[key]}: ${res.meta.errors[key]}`);
        });
      }

      if (sendErrorRes) {
        return { data: res, res: send };
      }
      return null;
    }

    if (res && res.meta && res.meta.message) {
      message.success(res.meta.message);
    }

    if (sendRes) {
      return { data: res, res: send };
    }
    return res;
  } catch (err) {
    message.error("server error!");
  }
};

export const GetData = async ({ url }) => {
  try {
    const headers = {
      s_id: session.get("s_id"),
      jwt: session.get("jwt"),
    };
    const send = await fetch(`${newUrl}${url}`, { headers });
    const res = await send.json();
    if (!send.ok) {
      if (res && res.meta && res.meta.errors) {
        Object.keys(res.meta.errors).map((key) => {
          message.error(`${[key]}: ${res.meta.errors[key]}`);
        });
      }
      return null;
    }

    return res;
  } catch (err) {
    return "server error!";
  }
};
