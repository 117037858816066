import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { apiClient } from "../../api";
import EmptyData from "../common/EmptyData";
import SpinWrapper from "../common/SpinWrapper";

const ViewWebsite = () => {
  console.log("hello");
  const { websiteDomain, pageId } = useParams();
  const websiteUrl = `website?domain_name=${websiteDomain}`;

  const { data: websiteData, error: websiteError } = useSWR(websiteUrl, () =>
    apiClient({
      method: "get",
      url: websiteUrl
    })
  );
  const loadingWebsite = !websiteData && !websiteError;
  const websiteId = websiteData?.data[0]?.id;
  const pagesUrl = `website_page?website=${websiteId}`;
  const { data: pagesData, error: pagesError } = useSWR(
    websiteId && pagesUrl,
    () =>
      apiClient({
        method: "get",
        url: pagesUrl
      })
  );
  const loadingPages = !pagesData && !pagesError;
  const pages = pagesData?.data;
  const loading = loadingWebsite || loadingPages;
  const findPage = pages?.find((page) => page.id === Number(pageId));
  const __html = pageId ? findPage?.html : pages && pages[0]?.html;

  if (
    !loadingWebsite &&
    (!websiteData?.data?.length || (!loadingPages && pageId && !findPage))
  ) {
    return <EmptyData description='Sorry, this page is not available' />;
  }
  if (loading) {
    return <SpinWrapper />;
  }
  return (
    <div>
      {pages && (
        <div
          dangerouslySetInnerHTML={{
            __html
          }}
        />
      )}
    </div>
  );
};

export default ViewWebsite;
